@use 'assets/scss/fonts';
@use 'assets/scss/themes/themes';
@use 'assets/scss/themes/themify';
@use 'assets/scss/overrides';

@import '../node_modules/primeflex/primeflex.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.css';

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include themify.themifyTopLevel(themes.$themes) {
    background: themify.themed('main-background-color');
    color: themify.themed('main-foreground-color');
    font-family: themify.themed('main-font'), -apple-system, BlinkMacSystemFont,
      'Segoe UI', sans-serif;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  line-height: 1.5;
}

a {
  @include themify.themifyTopLevel(themes.$themes) {
    color: themify.themed('main-link-color');
  }
  text-decoration: none;

  &:hover {
    @include themify.themifyTopLevel(themes.$themes) {
      color: themify.themed('main-link-color-hover');
    }
    text-decoration: none;
  }
}

.air-image {
  @include themify.themifyTopLevel(themes.$themes) {
    border-radius: themify.themed('main-border-radius');
  }

  object-fit: contain;
  width: 100%;
  height: auto;

  &:not(.nav-logo) {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        width: -webkit-fill-available;
      }
    }
  }
}

.air-list {
  list-style: none;
  padding-left: 0.75rem;
}

.no-scroll {
  overflow: hidden;
}

.alternate-text-link {
  @include themify.themifyTopLevel(themes.$themes) {
    border-bottom: 0.1rem solid themify.themed('main-foreground-color');
    color: themify.themed('main-foreground-color');
  }
  transition: all 0.15s ease-in;

  &:hover {
    @include themify.themifyTopLevel(themes.$themes) {
      box-shadow: inset 0 -0.05rem 0 themify.themed('main-foreground-color');
      color: themify.themed('main-foreground-color');
    }
  }
}
