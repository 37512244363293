@use 'src/assets/scss/themes/themify';

.p-component {
  @include themify.themifyTopLevel() {
    font-family: themify.themed('main-font'), -apple-system, BlinkMacSystemFont,
      'Segoe UI', sans-serif;
  }
}

.p-card {
  @include themify.themifyTopLevel() {
    border-radius: themify.themed('main-border-radius');
    color: themify.themed('main-foreground-color');
    background: themify.themed('card-background-color');
  }

  & .p-card-body {
    padding: 0.65rem;
  }

  & .p-card-content {
    padding: 0;
  }
}

p {
  margin: 0 0 0.65rem;
}

.p-inputtext {
  @include themify.themifyTopLevel() {
    background: themify.themed('card-background-color');
    color: themify.themed('main-foreground-color');
  }
}

.p-datatable-header {
  @include themify.themifyTopLevel() {
    background: themify.themed('main-background-color');
    color: themify.themed('main-foreground-color');
    border: 1px solid themify.themed('card-border-color');
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  @include themify.themifyTopLevel() {
    background: themify.themed('card-background-color');
    color: themify.themed('main-foreground-color');
    border: 1px solid themify.themed('card-border-color');
  }
}

.p-datatable-thead > tr > th {
  @include themify.themifyTopLevel() {
    background: themify.themed('main-background-color');
    color: themify.themed('main-foreground-color');
    border: 1px solid themify.themed('card-border-color');
  }
}

.p-datatable .p-paginator-bottom > *,
.p-datatable .p-paginator-bottom {
  @include themify.themifyTopLevel() {
    background: themify.themed('card-background-color');
    color: themify.themed('main-foreground-color');
    border: 0;
  }
}

.p-dropdown {
  @include themify.themifyTopLevel() {
    background: themify.themed('card-background-color');
    color: themify.themed('main-foreground-color');
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  @include themify.themifyTopLevel() {
    background: themify.themed('main-foreground-color');
    color: themify.themed('main-background-color');
  }
}

.p-button {
  @include themify.themifyTopLevel() {
    color: themify.themed('main-foreground-color-contrast');
    background: themify.themed('button-background-primary');
    border: 1px solid themify.themed('button-background-primary');
    border-radius: themify.themed('main-border-radius');
  }

  &:hover {
    @include themify.themifyTopLevel() {
      background: themify.themed('button-background-primary');
      border: 1px solid themify.themed('button-background-primary');
      opacity: 0.8;
    }
  }
}
.p-panel .p-panel-header {
  @include themify.themifyTopLevel() {
    background-color: themify.themed('table-stripe-alternate-background');
    border-radius: themify.themed('main-border-radius');
    color: themify.themed('main-foreground-color');
  }
  border: 0 !important;
}

.p-panel {
  margin-bottom: 0.5rem;
}

.p-panel .p-panel-content {
  @include themify.themifyTopLevel() {
    background: themify.themed('main-background-color');
    color: themify.themed('main-foreground-color');
  }
  border: 0 !important;
  padding-bottom: 0.5rem !important;
}

.p-panel .p-panel-header .p-panel-header-icon {
  @include themify.themifyTopLevel() {
    color: themify.themed('main-foreground-color');
  }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  // TODO rh den ganzen block zusammenfügen
  @include themify.themifyTopLevel() {
    background: themify.themed('main-background-color');
    color: themify.themed('main-foreground-color');
    border: 1px solid darken(themify.themed("main-background-color"), 5%) !important;
  }
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: unset;
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  @include themify.themifyTopLevel() {
    background: lighten(themify.themed('main-background-color'), 5%);
    color: themify.themed('main-foreground-color');
  }
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  @include themify.themifyTopLevel() {
    background: lighten(themify.themed('main-background-color'), 5%);
    color: themify.themed('main-foreground-color');
  }
}
.p-accordion .p-accordion-content {
  @include themify.themifyTopLevel() {
    background: lighten(themify.themed('main-background-color'), 2%);
    color: themify.themed('main-foreground-color');
    border: 1px solid darken(themify.themed(main-background-color), 5%) !important;
  }
}

.p-overlaypanel {
  @include themify.themifyTopLevel() {
    background: lighten(themify.themed('main-background-color'), 2%);
  }
}

a:hover,
a.hover,
button:hover {
  @include themify.themifyTopLevel() {
    color: themify.themed('main-link-color-hover');
  }
}

.p-image-mask {
  z-index: 100000 !important;
}

.p-image-toolbar {
  z-index: 10000;
}

.p-sidebar-left {
  width: unset;
}
